<template>
    <div>
      <highcharts :options="chartOptions" :constructor-type="'chart'"></highcharts>
    </div>
  </template>
  
  <script>
  import { Chart } from 'highcharts-vue';
  
  export default {
    components: {
      highcharts: Chart
    },
    props: {
      chartOptions: Object
    }
  }
  </script>
  