<template>
  <v-card>
    <survey-chart
      v-for="(options, index) in chartsOptions"
      :key="index"
      :chart-options="options"
    />
  </v-card>
</template>

<script>
import SurveyChart from "./components/SurveyChart.vue";
import RestResource from "../../../services/dataServiceMobileV2";
const service = new RestResource();

export default {
  components: {
    SurveyChart,
  },
  data() {
    return {
      chartsOptions: [],
    };
  },

  mounted() {
    this.loadTaskData();
  },

  methods: {
    loadTaskData() {
      this.$setLoader();
      service.getTaskData({ taskId: this.$route.params.id }).then((res) => {
        this.chartsOptions = this.generateChartsOptions(res.data);
        this.$disableLoader();
      });
    },
    generateChartsOptions(data) {
      return data.map((question) => {
        // Count the frequency of each answer
        const counts = question.answers.reduce((acc, answer) => {
          acc[answer] = acc[answer] ? acc[answer] + 1 : 1;
          return acc;
        }, {});

        // Prepare the categories and series data for Highcharts
        const categories = Object.keys(counts);
        const seriesData = Object.values(counts).map((count) => count);

        return {
          chart: {
            type: "column", // or 'bar', 'pie', etc., depending on your preference
          },
          title: {
            text: question._id,
          },
          xAxis: {
            categories: categories, // Dynamic categories based on the responses
            crosshair: true,
          },
          yAxis: {
            min: 0,
            title: {
              text: "Number of Responses",
            },
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              name: "Number of Responses",
              data: seriesData,
            },
          ],
        };
      });
    },
  },
};
</script>
